<template>
  <div class="conTent">
    <header>
      <van-nav-bar
        title="详情信息"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
      />
    </header>
    <div class="main" v-if="homeData.name">
      <h2>
        {{ homeData.name || "" }}
      </h2>
      <div class="content">
        {{ homeData.introduce || "" }}
      </div>

      <!-- 景点详情部分 -->
      <ul class="label">
        <li v-if="homeData.optimalTimePeriod">
          最佳旅游时间: {{ homeData.optimalTimePeriod }}
        </li>
        <li v-if="homeData.scenicSpotLevel">
          级别: {{ homeData.scenicSpotLevel }}
        </li>
        <li v-if="homeData.admissionTicket">
          门票:{{ homeData.admissionTicket }}
        </li>
        <li v-if="homeData.address">地址: {{ homeData.address }}</li>
        <li v-if="homeData.telephone">电话: {{ homeData.telephone }}</li>
      </ul>

      <!-- 美食详情 -->
      <ul class="label" v-if="homeData.deliciousFoodName">
        <!-- <li>美食名称: {{ homeData.deliciousFoodName }}</li>
        <li>详解: {{ homeData.deliciousFoodIntroduce }}</li> -->
      </ul>

      <img v-if="homeData.imgUrl" :src="homeData.imgUrl" alt="" />
      <!-- 视频标签 -->
      <nut-video
        v-if="this.sources.src"
        class="video"
        :sources="sources"
        :options="options"
        ref="videoDom"
        style="width: 100%; height: 1.5rem"
      >
      </nut-video>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      sources: [
        {
          src: "https://storage.jd.com/about/big-final.mp4?Expires=3730193075&AccessKey=3LoYX1dQWa6ZXzQl&Signature=ViMFjz%2BOkBxS%2FY1rjtUVqbopbJI%3D",
          type: "video/mp4",
        },
      ],
      options: {
        controls: true,
      },
      list: [],
    };
  },
  computed: {
    ...mapState(["homeData"]),
  },
  created() {
    this.list = this.homeData;
  },
  methods: {
    onClickLeft() {
      this.$router.go("-1");
    },
    play(elm) {
      console.log("play", elm);
    },
    pause(e) {
      console.log("pause");
    },
    playend(e) {
      alert("播放结束");
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  padding: 0.1rem 0.2rem;
  h2 {
    font-size: 0.32rem;
    line-height: 0.6rem;
  }
  p {
    font-size: 0.2rem;
  }
  // 内容讲解
  .content {
    margin: 0.2rem 0;
    text-indent: 2em;
  }
  .label {
    font-size: 0.26rem;
    line-height: 0.36rem;
  }
  /deep/ .nut-video .nut-videoplayer {
    margin-top: 0.3rem;
    width: 96%;
    height: 20vh;
  }
  img {
    width: 100%;
  }
}
</style>